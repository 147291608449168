<template>
  <q-form
    @submit="onSubmit"
    class="q-gutter-md"
  >
    <q-input
      dense
      outlined
      type="email"
      ref="email"
      v-model="credentials.email"
      placeholder="E-mail do Reuni"
      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      :spellcheck="false"
      no-error-icon
      hide-bottom-space
      :rules="[
        () => $v.credentials.email.required || 'E-mail é obrigatório',
        () => $v.credentials.email.email || 'E-mail inválido'
      ]"
    >
      <template #prepend>
        <q-icon
          name="mdi-account"
          size="xs"
        />
      </template>

      <template #append>
        <q-icon
          size="xs"
          name="mdi-close"
          class="cursor-pointer"
          v-show="credentials.email"
          @click="credentials.email = ''"
        />
      </template>
    </q-input>

    <FieldCore
      label="Núcleo"
      name="nucleoId"
      class="col-12"
      async-key="reuniId"
      v-model="credentials.nucleoId"
      :is-required="true"
    />

    <div class="row items-center justify-between">
      <q-btn
        flat
        dense
        no-caps
        padding="0"
        :ripple="false"
        color="primary"
        label="Voltar"
        :to="authLoginRoute"
      />

      <AuthSubmit
        label="Cadastrar"
        :loading="auth.loading"
      />
    </div>
  </q-form>
</template>

<script>
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { ROUTE_AUTH_SIGN_IN, ACTION_SIGN_UP } from '../constants'
import AuthSubmit from '../components/AuthSubmit'
import FieldCore from '@/modules/documents/components/FieldCore'

export default {
  components: {
    FieldCore,
    AuthSubmit
  },

  data () {
    return {
      credentials: {},
      authLoginRoute: { name: ROUTE_AUTH_SIGN_IN }
    }
  },

  mounted () {
    this.$nextTick().then(() => (
      this.$refs.email.focus()
    ))
  },

  computed: {
    ...mapState({
      auth: ({ authStore }) => authStore
    })
  },

  validations: {
    credentials: {
      email: { required, email }
    }
  },

  methods: {
    onSubmit () {
      if (this.auth.loading) return
      this.$store.dispatch(ACTION_SIGN_UP, this.credentials)
    }
  }
}
</script>
